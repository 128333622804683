import { SearchHit, SearchHitTypes } from "frontend/types";

export function isSearchHitType<T extends SearchHit>(
  hitType: SearchHitTypes,
  searchHit?: SearchHit,
): searchHit is T {
  if (searchHit === undefined) return false;

  if (hitType === "modern") {
    return "url" in searchHit && "title" in searchHit;
  }
  if (hitType === "hollis") {
    return "title" in searchHit;
  }
  if (hitType === "modernArticle") {
    return "contentType" in searchHit && searchHit.contentType === "Article";
  }
  if (hitType === "modernProgram") {
    return "contentType" in searchHit && searchHit.contentType === "Program";
  }
  if (hitType === "legacy" || hitType === "legacyArticle") {
    return "HBSLink" in searchHit && "HBSTitle" in searchHit;
  }

  return false;
}
