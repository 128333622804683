import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import { QueryEntry } from "frontend/contentful/schema/semantics";
import { SearchQuery, SearchQueryIndex } from "frontend/types";

export type UseSearchPropsOptions = Partial<SearchQuery>;

export const createUseSearchProps = createContentfulProps<
  QueryEntry,
  SearchQuery | null,
  UseSearchPropsOptions
>(({ entry, options = {} }) => {
  if (!entry.fields.query) return null;

  const query: SearchQuery = {
    ...options,
    query:
      options.query ||
      "(" + entry.fields.query + ") AND -HBSDataLifecycle:Draft",
    size: options.size || parseInt(entry.fields.limit || "25", 10),
    index:
      options.index || ((entry.fields.index || "modern") as SearchQueryIndex),
  };

  const sortValue = entry.fields.sort;

  if (!query.sort && sortValue) {
    query.sort = sortValue.join(",");

    // legacy default sort by HBSSortDate
    if (!query.sort && entry.fields.index === "legacy") {
      query.sort = "HBSSortDate";
    }
  }

  return query;
});
