import { BylinePerson } from "design-system/components/primitives/byline/byline";
import { createContentfulProps } from "frontend/contentful/lib/create-contentful-props";
import {
  PersonEntry,
  PersonWrapperEntry,
} from "frontend/contentful/schema/semantics";
import { createPersonImageProps } from "../primitives/media-asset.props";
import { useContentful } from "frontend/hooks/use-contentful";
import { isContentType } from "frontend/contentful/lib/is-content-type";

export const createBylinePersonProps = createContentfulProps<
  PersonEntry | PersonWrapperEntry,
  BylinePerson
>(({ entry, createEditAttributes }) => {
  const { getPersonLink, getPersonName, getEntry } = useContentful();

  let personEntry: PersonEntry | undefined;

  if (isContentType<PersonWrapperEntry>("personWrapper", entry)) {
    personEntry = getEntry(entry.fields.person);
  } else {
    personEntry = entry;
  }

  const props: BylinePerson = {
    name: getPersonName(entry),
    link: getPersonLink(entry),
    avatar: createPersonImageProps(entry),
    editAttributes: {
      name: createEditAttributes({ entry, fieldId: "firstName" }),
    },
  };

  if (personEntry?.fields.firstName || entry?.fields.displayName) return props;
});
